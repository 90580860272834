import queryString from 'query-string';

export const loadAccesstrade = () => {
  const script = document.createElement('script');
  script.src = '//static.accesstrade.vn/js/trackingtag/tracking.min.js';
  script.id = 'accesstrade';
  document.body.appendChild(script);
  script.onload = () => {
    window.AT.init({ 
      'campaign_id': 'drobebox',
      'is_reoccur': 1,
      'is_lastclick': 1,
      });
    window.AT.track();
  };
}

export  const initUserConversion = () => {
  const params = queryString.parse(window.location.search);
  if (params.utm_source === 'accesstrade' && params.aff_sid) {
    const data = JSON.stringify({ source: params.utm_source, tracking_id: params.aff_sid });
    const url = 'https://kalzx6tc3k.execute-api.ap-southeast-1.amazonaws.com/prod/conversion';
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(data);
  }
}
